import axios from '@axios'

export default function contentCategoryServices() {
  const getCategories = async (queryParams) => {
    const api = await axios.get('content-categories', queryParams)
    return api.data.response.categories
  }

  const createCategory = async (queryParams) => {
    const api = await axios.post('content-categy', queryParams)
    return api
  }

  const updateCategory = async (queryParams) => {
    const api = await axios.post('content-categy', queryParams)
    return api
  }

  const deleteCategory = async (queryParams) => {
    const api = await axios.post('content-categy', queryParams)
    return api
  }

  return {
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
  }
}
